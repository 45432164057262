<template>
    <div :class="['addGiftDeletion', operate === 'add' ? 'addGiftDeletion-pd' : '']">
      <div style="padding: 16px 0; position: sticky; top: 0; z-index: 100; background: #f5f5f5;">
        <div class="common-t">
          <div class="common-t-l">{{ $t('礼品报缺清单') }}</div>
          <div v-if="operate === 'add'" class="common-t-r" @click="addItem">{{ $t('添加') }}</div>
          <div v-else class="common-t-r">{{ status == 1 ? $t('未处理') : $t('已处理') }}</div>
        </div>
      </div>
      <van-form ref="formRef" @submit.native.prevent>
        <div class="content">
          <div v-for="(item, index) in formData.presentDetails" :key="index" class="common-block">
            <van-field
              v-model="item.presentName"
              :label="$t('报缺礼品名称')"
              :placeholder="$t('请填写报缺礼品名称')"
              required
              maxlength="20"
              :readonly="operate === 'detail'"
              :rules="[{required: true}]">
            </van-field>
            <van-field
              v-model="item.num"
              :label="$t('数量')"
              type="number"
              maxlength="3"
              :placeholder="$t('请填写报缺礼品数量(0-999)')"
              required
              :readonly="operate === 'detail'"
              :rules="[{ required: true, validator: validateCount }]">
              <template v-if="index !== 0 && operate === 'add'" #extra>
                <div style="color: #B9921A" @click="deleteItem(item)" >{{ $t('删除') }}</div>
              </template>
            </van-field>
          </div>
        </div>
        <div v-if="operate === 'add'" class="common-footer">
          <van-button class="btn back" @click.stop="goBack">{{$t('取消')}}</van-button>
          <van-button class="btn submit" :loading="submitLoading" @click="onSubmit">{{ $t('提交') }}</van-button>
        </div>
      </van-form>
    </div>
</template>
<script>
import giftDeletionServices from '@/services/giftDeletionServices'
import loading from '@/utils/loading'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'GiftDeletionAdd',
  data() {
    return {
      submitLoading: false,
      operate: 'add',
      formData: {
        presentDetails: []
      },
      status: ''
    }
  },
  mounted() {
    const { operate, id } = this.$route.query
    this.operate = operate
    if (this.operate == 'add') {
      this.formData.presentDetails.push({ presentName: '', num: '', uuid: uuidv4() })
    } else {
      this.getDetail(id)
    }
  },
  methods: {
    async getDetail(id) {
      loading.showLoading()
      const result = await giftDeletionServices.giftDeletionDetail({ id })
      loading.hideLoading()
      const { status, details } = result
      this.status = status
      this.formData.presentDetails = details
    },
    goBack() {
      this.$router.go(-1)
    },
    validateCount(value) {
      if (!value) {
        return false
      }
      if (value < 0 || value > 999) {
        return false
      }
      return true
    },
    // 添加
    addItem() {
      this.formData.presentDetails.push(
        { presentName: '', num: '', uuid: uuidv4() }
      )
    },
    // 删除
    deleteItem(item) {
      this.formData.presentDetails = this.formData.presentDetails.filter(({ uuid }) => { return uuid !== item.uuid })
    },
    onSubmit() {
      this.$refs.formRef.validate().then(async() => {
        loading.showLoading()
        const result = await giftDeletionServices.giftDeletionApplay(this.formData)
        loading.hideLoading()
        this.$toast.success(this.$t('操作成功'))
        setTimeout(() => {
          this.$router.go(-1)
        }, 1500)
      }).catch(() => {
      })
    },
  }
}
</script>
<style lang="less" scoped>
.addGiftDeletion{
  padding: 0px 16px 0 16px;
  &.addGiftDeletion-pd{
    padding-bottom: 96px;
  }
  .content{
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    .common-block{
      display: flex;
      overflow: hidden;
      margin-bottom: 8px;
      flex-direction: column;
    }
  }
  .common-t{
    background: #fff;
    padding: 12px;
    border-bottom: none;
    .common-t-l::before{
      background: @yellow;
    }
    .common-t-r{
      color: @yellow-text
    }
  }
}
</style>